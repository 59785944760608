//const WebSocket = require("ws");
import axios from 'axios';
import webln from 'webln';
import { requestProvider } from '@getalby/bitcoin-connect';
import * as Constants from '../common/constants.js'
import SHA256 from 'crypto-js/sha256';
import encHex from 'crypto-js/enc-hex';
import { getJob,addJob,removeJob, updateJob } from './components/jobHistoryManager.js';
import { FileStack, ListTodo, Youtube, PenTool, Quote, MessageSquare, ArrowRight, Twitter, Linkedin , Instagram} from 'lucide-react';



const { relayInit } = require("nostr-tools");
const { sleep } = require("../lib/helpers")
const { OFFERING_KIND } = require('../lib/defines')
const relayURL = "wss://nostr.kungfu-g.rip";
const hostname = "https://cascdr-auth-backend-cw4nk.ondigitalocean.app"
const debug = false;

const AuthCategory = {
  FREE_TIER: 0 ,
  TOKEN: 1,
  LIGHTNING: 2,
};

const PaymentChoiceStatus = {
  CHOOSE_PAYMENT_METHOD: "choosePaymentMethod",
  SIGN_IN_OR_UP: "signInOrUp",
  DONE: "done",
  LIGHTNING_CHOSEN:"lightningChosen",
  SQUARE_CHECKOUT:"squareCheckout",
  SQUARE_CHECKOUT_SUCCEEDED:"squareCheckoutSucceeded",
  NULL: null, // Adding a NULL state to represent no choice made
};

export const defaultRSSPrompts = {
  Summary: "Analyze the provided content and create a concise summary. Use context clues to determine the media type (e.g., podcast, video, interview). Structure your response in markdown as follows:\n\n1. TLDR: 2-3 sentences overview\n2. Key Points: 5-10 bullet points (adjust based on content length)\n3. Top Quotes: 2-3 significant quotes\n4. Conclusion: Brief wrap-up\n\nAvoid directly referencing the source material.",

  Article: "Based on the provided content, write an engaging blog post about the core ideas discussed. Use context clues to infer the source material type if necessary. Craft your article in an opinion piece style, focusing on the main concepts without directly referencing the transcript. Aim for eloquence and clarity in your writing.",

  Quotes: "Extract and refine the 5 most thought-provoking quotes from the provided content. Use context clues to determine the media type if needed. Remove filler words (e.g., 'uh', 'um', 'like') to enhance eloquence, but maintain the speaker's original intent and message.",

  // HowTo: "Create a detailed, enumerated list explaining all technical steps required to complete the project described in the provided content. Ensure clarity and thoroughness in your instructions.",

  // PromoBlog: "Compose a promotional summary of the provided content as a short-form blog post for a website. Write 4-10 well-structured paragraphs covering all high-level details and summarizing the topics discussed. Aim for an engaging and informative tone.",

  "YouTube Description": "Based on the provided content, craft a compelling and descriptive YouTube video description. Be informative while optimizing for relevant keywords to improve discoverability. Highlight the main topics and any unique aspects that would attract potential viewers.",

  "Custom Prompt": "",

  "Show Notes": "Based on the provided transcript content, generate detailed and listener-friendly show notes for a podcast episode. Follow this structure:\n\n1. **Episode Title**: Suggest a captivating title that highlights the core theme of the episode.\n2. **Episode Description**: Provide a 2-3 sentence summary of the episode's main points.\n3. **Key Takeaways**: Outline 3-5 bullet points summarizing the most important ideas or topics discussed.\n4. **Timestamps (Optional)**: If specific moments or topics can be referenced by time, include timestamps.\n5. **Guest Information (if applicable)**: Briefly introduce the guest(s) and their relevance to the topic.\n6. **Resources and Links**: Mention any books, articles, tools, or media references discussed, including relevant links.\n7. **Call to Action**: Include any next steps for the audience, such as subscribing, checking out additional content, or leaving feedback.\n\nUse clear and concise language. Aim to make the show notes engaging and easy to scan for quick reference. If filler words (e.g., 'uh', 'um', 'like') appear in the transcript, omit them for clarity.",

  "LinkedIn Post": "Based on the provided content, write a professional LinkedIn post that highlights key insights and encourages engagement from a professional audience. The post should be structured concisely without any metadata tags. Focus on providing valuable takeaways and invite the audience to reflect or comment. Ensure relevant, high-traffic hashtags are used (e.g., #AI, #Innovation, #Leadership).\n\n**Example Structure:**\n\n- Introduce the insight or idea in an engaging way.\n- Provide 1-2 sentences that expand on the topic or idea.\n- Pose a thought-provoking question or invitation for comments.\n- Use high-traffic hashtags (e.g., #AI, #Technology, #Leadership).",

  "Twitter Post": "Write a concise and engaging Twitter (X) post based on the provided content. Focus on a single, impactful idea or takeaway that fits within Twitter's character limit (280 characters). Avoid metadata tags, and focus on capturing attention. End the post with a call to action or thought-provoking statement, followed by conservative and high-traffic hashtags (e.g., #AI, #Innovation).\n\n**Example Structure:**\n\n- Summarize the key takeaway or insight in a short sentence.\n- End with a call to action or thought-provoking question.\n- Use relevant high-traffic hashtags (e.g., #AI, #Tech, #Innovation).",

  "IG Caption": "Create an engaging Instagram caption based on the provided content. Focus on a compelling hook that draws attention and fits Instagram’s community-oriented tone. Expand on the insight or idea in 2-3 sentences and encourage interaction through likes, comments, or shares. Use high-traffic, relevant hashtags to boost engagement (e.g., #AI, #Innovation, #TechCommunity).\n\n**Example Structure:**\n\n- Start with an attention-grabbing hook or statement.\n- Provide 2-3 sentences that expand on the topic.\n- End with a question or invitation to comment.\n- Use 4-5 high-traffic hashtags (e.g., #AI, #Tech, #Innovation, #TechCommunity)."
};

export const contentTypes = [
  { id: 'Summary', label: 'Summary', icon: FileStack },
  { id: 'Show Notes', label: 'Show Notes', icon: ListTodo },
  { id: 'YouTube Description', label: 'YT Description', icon: Youtube },
  { id: 'Article', label: 'Blog Post', icon: PenTool },
  { id: 'Quotes', label: 'Top Quotes', icon: Quote },
  { id: 'Custom Prompt', label: 'Custom Prompt', icon: MessageSquare },
  { id: 'Twitter Post', label: 'Twitter Post', icon: Twitter },
  { id: 'LinkedIn Post', label: 'LinkedIn Post', icon: Linkedin },
  { id: 'IG Caption', label: 'IG Caption', icon: Instagram },
];



//require("dotenv").config();
global.WebSocket = WebSocket;

var queuedJob = null;

// ----------------- HELPERS ---------------------------

function storeJobHistory(serviceName,inputField1,inputField2,responseData){
  // try{
    console.log(`response in storeJobHistory:${JSON.stringify(responseData,null,2)}`)
    const paymentHash = responseData.paymentHash;
    addJob(serviceName,inputField1,inputField2,paymentHash);
    console.log(`successful storeJobHistory for paymentHash:${paymentHash}`)
  // }
  // catch(error){
  //   console.log(`storeJobHistory error:${JSON.stringify(error,null,2)}`)
  // }
}

function setQueuedJob(requestData,service){
  const queuedJob = {
    requestData: requestData,
    service: service
  }

  localStorage.setItem("queuedJob",JSON.stringify(queuedJob))
}

function getQueuedJob(){
  const queuedJob = JSON.parse(localStorage.getItem("queuedJob"))
  return queuedJob;
}

function resetQueuedJob(){
  localStorage.setItem("queuedJob",null)
}

function createGuidFromUrl(url) {
  const hash = SHA256(url).toString(encHex);
  const guid = `${hash.substr(0,8)}-${hash.substr(8,4)}-${hash.substr(12,4)}-${hash.substr(16,4)}-${hash.substr(20,12)}`;
  return guid;
};

async function recordContactInfo(contact_type,contact_data){
  const data = {
    contact_type: contact_type, // Replace with the actual contact type
    contact_data: contact_data, // Replace with the actual contact data
  };

  var config = {
    method: 'post',
    url: `${hostname}/send-contact`,
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };

  return axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
  })
  .catch(function (error) {
    console.log(error);
  });
}

function getLatestEventByService(events, desiredService) {
  //console.log(`Events for service ${desiredService}:`, events.map(JSON.stringify));
  const targetPubkey = "cc18a647c7dd673fad8ee6880c310dcd237b28359f622c26fc8aa85325e48aa7"
  //console.log(`getLatestEventByService events:`, events);
  return events
    .filter((event) => {
      const serviceTag = event.tags.find((tag) => tag[0] === "s");
      const pubkey = event.pubkey;
      const pass = serviceTag && serviceTag[1] === desiredService && targetPubkey == pubkey;
      return pass;
    })
    .sort((a, b) => b.created_at - a.created_at)[0];
}

function getAuthHeader(){
  const authToken = localStorage.getItem("cascdrAuthJwt");
  if(!authToken){return `Bearer: no-token`}
  return `Bearer: ${authToken}`
}

async function pollUrl(url, runs, delay, authData) {
  console.log("authData:", authData);

  // Conditionally set headers based on authData
  let headers = {};

  headers['Authorization'] = authData.authHeader;

  for (let i = 0; i < runs; i++) {
    try {
      console.log(`Polling url: ${url}`);
      const response = await axios.get(url, { headers });

      if (response.status === 202) {
        throw new Error("Not ready yet");
      }

      return response.data;
    } catch (error) {
      // Log error or handle it as necessary
      console.log(`Attempt ${i + 1} failed for ${url}. Error: ${error.message}`);

      if (i === runs - 1 || error.response?.status === 500) {
        throw new Error("Poll Timeout");
      }
      await sleep(delay);
    }
  }
}

function getIsSignedIn(){
  const email = localStorage.getItem("email");
  if(email){
      return true;
  }
  else{
    return false;
  }
}

function getHasEmailAndSubscription(){
  const isSubscribed = localStorage.getItem("isSubscribed");
  if(isSubscribed === "true"){
      return true;
  }
  else{
    return false;
  }
}

async function requestLNProvider(){
  try{
    console.log("request LN provider...")
    const provider = await requestProvider();
    console.log("got LN provider:", provider)
    return provider;
  }
  catch{
    console.log("error getting LN provider")
  }
  return null;
}

async function respondToPaymentRequest(responseData) {
  var authData = {
    authCategory: responseData.authCategory //free, token or lightning
  }
  try {
    const authHeader = getAuthHeader();
    if(responseData.authCategory == AuthCategory.FREE_TIER){
      authData.authHeader = authHeader
      // console.log("Eligible for free tier")
      return authData
    }
    else if(responseData.authCategory === AuthCategory.TOKEN && authHeader){
      //grab the cascdrAuthJwt and pass it back for the final request
      // console.log("user is subscribed")
      authData.authHeader = authHeader
      return authData
    }
    else if(localStorage.getItem("bc:config")){
      // console.log("user has bc:config set up")
      // console.log("about to pay invoice from responseData:", responseData)
      const provider = await requestProvider();
      const response = await provider.sendPayment(responseData.pr);
      // console.log("Payment response:", response);
      authData.authHeader = `:${response.preimage}`
      return authData;
    }
    else{
      //prompt user to start flow
      console.log("auth category not-selected")
      authData.authCategory = "not-selected";
      return authData
    }
    
  } catch (err) {
    console.error('Error during payment:', err);
    throw err;
  }
}



// ----------------- GPT ---------------------------

function parseGPTResponse(response) {
  if (response.choices && response.choices.length > 0) {
    const assistantMessage = response.choices[0].message;
    if (assistantMessage.role === "assistant") {
      return assistantMessage.content.trim(); // Using trim() to remove any unnecessary whitespace
    }
  }
  return null;
}


async function runImage2Text(relay, index, image,additionalPrompt) {
    return new Promise(async (resolve, reject) => {
    // --------------------- Fetch Offering Event -----------------------------

    var endpoint = "http://localhost:9000/analyze-uploaded-image";
    var postedNote = null;
    let headers = {}
    if(debug == false){
      // const postedNoteList = await relay.list([
      //   {
      //     kinds: [OFFERING_KIND],
      //     limit: 10,
      //   },
      // ]);
  
      // postedNote = getLatestEventByService(
      //   postedNoteList,
      //   "gpt-4-vision-preview-from-upload"
      // );
      // const postedNoteContent = JSON.parse(postedNote.content);
      // endpoint = postedNoteContent.endpoint;
      endpoint = "https://image2text-ai5zz.ondigitalocean.app/analyze-uploaded-image"
    }


    // --------------------- Post to Note's endpoint -----------------------------
    const formData = new FormData();
    formData.append('image', image);
    // const headers = {
    //   'Content-Type': `multipart/form-data; boundary=${formData._boundary}`, // Manually set the boundary
    //   // Other headers if needed
    // };
    formData.append("follow_up_question",additionalPrompt)

    let responseData;
    //console.log("requestData:", requestData)
    try {
      const authHeader = getAuthHeader();
      if (authHeader) {
        headers['Authorization'] = authHeader;
      }
      const response = await axios.post(
        endpoint,
        formData, 
         {headers}
      );
      responseData = response.data;
    } catch (e) {
      if (e.response && e.response.status === 402) {
        responseData = e.response.data;
      } else {
        throw new Error(`Bad Request ${e}`);
      }
    }

    // --------------------- Pay invoice -----------------------------

    console.log(`------- PAYING Image2Text Service ${index} --------`);
    console.log(responseData.pr);
    console.log("----------------------------");
    const authData = await respondToPaymentRequest(responseData);
    console.log("------- PAID Image2Text Service ---------------");
    console.log("authData:",authData);
    console.log("----------------------------");

    if(authData.authCategory === "not-selected"){
      // setQueuedJob(image,"image2text")
      console.log("ending prematurely - sign in or bitcoin connect")
      resolve(authData);
      return;
    }

    // --------------------- Poll SuccessAction for Response -----------------------------

    const totalResponse = await pollUrl(
      responseData.successAction.url,
      999,
      5000,
      authData
    );

    if (totalResponse.choices && 
      totalResponse.choices.length > 0 && 
      totalResponse.choices[0].message && 
      totalResponse.choices[0].message.content) {
    
      const content = totalResponse.choices[0].message.content;
      // Now that we have the content, we can resolve the promise with it
      resolve(content);
    } else {
      // If the structure is not as expected, reject the promise
      console.log("error with:",JSON.stringify(totalResponse,null,2))
      reject(new Error("Invalid response structure"));
    }
  });
}

async function runRssExtractor(relay, index, searchTerm,audioOnly=false) {
  return new Promise(async (resolve, reject) => {
    // --------------------- Fetch Offering Event -----------------------------
    let endpoint = (debug == false) ? "https://rss-extractor-app-yufbq.ondigitalocean.app/getRssAudio" : "http://localhost:5001/getRssAudio";
    var postedNote = null;
    if(debug === false && relay != null){
      try{
        const postedNoteList = await relay.list([
          {
            kinds: [OFFERING_KIND],
            limit: 10,
          },
        ]);
    
        const postedNote = getLatestEventByService(
          postedNoteList,
          "https://ytdl.com/"
        );
        const postedNoteContent = JSON.parse(postedNote.content);
        if (postedNoteContent?.endpoint){
          endpoint = postedNoteContent.endpoint;
        }
      }
      catch{
        console.log("error fetching postedNote")
        endpoint = "https://rss-extractor-app-yufbq.ondigitalocean.app/getRssAudio"
      }    
    }

    // --------------------- Post to Note's endpoint -----------------------------

    const requestData = {
      'offset': 0,
      'podcastName': searchTerm //TODO: needs to be parsed and extracted,
    };

    let responseData;
    try {
      const headers = {};
      const authHeader = getAuthHeader();
      console.log("got authHeader for POST:", authHeader)
      if (authHeader) {
          headers['Authorization'] = authHeader;
      }
      const response = await axios.post(
        endpoint,
        requestData,
        { headers }
      );
      responseData = response.data;
    } catch (e) {
      if (e.response && e.response.status === 402) {
        responseData = e.response.data;
      } else {
        throw new Error(`Bad Request ${e}`);
      }
    }

    // --------------------- Pay invoice -----------------------------

    console.log(`------- PAYING Yitter ${index} --------`);
    console.log(responseData.pr);
    console.log("----------------------------");
    const authData = await respondToPaymentRequest(responseData);
    console.log("------- PAID Yitter ---------------");
    console.log("authData:",authData);
    console.log("----------------------------");

    // --------------------- Poll SuccessAction for Response -----------------------------

    if(authData.authCategory === "not-selected"){
      setQueuedJob(searchTerm,"yitter")
      console.log("ending prematurely - sign in or bitcoin connect")
      resolve(authData);
      return;
    }

    console.log(`------- Waiting for Yitter... ----------------`);
    var totalResponse = await axios.get(responseData.successAction.url);
    if(totalResponse.data.state === "WORKING"){
      totalResponse = await axios.get(responseData.successAction.url);
    }

    
    const yitterResponse = totalResponse.data["itemUrl"];
    const guid = totalResponse.data["episodeGUID"]

    console.log(`------- Yitter ( ${index} ) ----------------`);
    console.log(totalResponse)
    if(postedNote){console.log(`${postedNote.s}: ${yitterResponse}`);}
    console.log("----------------------------");

    resolve({ itemUrl: yitterResponse, guid: guid });
  });
}

async function runWhisper_direct_upload(relay,index,filePath,skipPoll=false){
  return new Promise(async (resolve, reject) => {
    // --------------------- Fetch Offering Event -----------------------------

    let endpoint = "http://localhost:5004/WHSPR";
    var postedNote = null;
    if(debug === false){
      // const postedNoteList = await relay.list([
      //   {
      //     kinds: [OFFERING_KIND],
      //     limit: 10,
      //   },
      // ]);
  
      // const postedNote = getLatestEventByService(
      //   postedNoteList,
      //   "https://api.openai.com/v1/audio/transcriptions"
      // );
      // const postedNoteContent = JSON.parse(postedNote.content);
      // endpoint = postedNoteContent.endpoint;
      endpoint=`${Constants.WHISPR_ENDPOINT}/WHSPR`
    }

    
    //console.log(`postedNoteContent:`,postedNoteContent)

    // --------------------- Post to Note's endpoint -----------------------------
    const formData = new FormData();
    formData.append('audio', filePath);
    let headers = {
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`, // Manually set the boundary
      // Other headers if needed
    };
    

    let responseData;
    try {
      const authHeader = getAuthHeader();
      console.log("got authHeader for POST:", authHeader)
      if (authHeader) {
          headers['Authorization'] = authHeader;
      }
      const response = await axios.post(
        endpoint,
        formData, 
        {headers}
      );
      responseData = response.data;
      console.log("responseData",responseData)
    } catch (e) {
      if (e.response && e.response.status === 402) {
        responseData = e.response.data;
      } else {
        throw new Error(`Bad Request ${e}`);
      }
    }

    console.log(`Storing job history for ${filePath instanceof File ? filePath.name : filePath}`);
    let fileName = filePath instanceof File ? filePath.name : 
                  (typeof filePath === 'string' ? filePath : 'Unknown File');
    storeJobHistory('Jamie', 'Transcript from Upload', fileName, responseData);

    // --------------------- Pay invoice -----------------------------

    console.log(`------- PAYING Whisper ${index} --------`);
    console.log(responseData.pr);
    console.log("----------------------------");
    const authData = await respondToPaymentRequest(responseData);
    console.log("------- PAID Whisper ---------------");
    console.log("authData:",authData);
    console.log("----------------------------");

    // --------------------- Poll SuccessAction for Response -----------------------------

    if(authData.authCategory === "not-selected"){
      setQueuedJob(filePath,"WHSPR")
      console.log("ending prematurely - sign in or bitcoin connect")
      resolve(authData);
      return;
    }

    if(skipPoll){
      resolve({
        success: responseData.paymentHash !== null,
        authCategory:authData.authCategory,
        paymentHash:responseData.paymentHash
      })
    }

    const paymentHash = responseData.paymentHash;
    const totalResponse = await pollUrl(
      responseData.successAction.url,
      999,
      5000,
      authData
    );
    let whisperResponse = totalResponse;
    whisperResponse.paymentHash = paymentHash;

    console.log(`------- Whisper ( ${index} ) ----------------`);
    console.log(totalResponse)
    console.log("----------------------------");

    resolve(whisperResponse);
  });
}

async function runWhisper(relay, index, mediaLink,guid=null,skipPoll=false) {
  return new Promise(async (resolve, reject) => {
    // --------------------- Fetch Offering Event -----------------------------

    let endpoint = "http://localhost:5004/WHSPR";
    var postedNote = null;
    let headers = {}
    if(debug === false){
      endpoint=`${Constants.WHISPR_ENDPOINT}/WHSPR`
      // const postedNoteList = await relay.list([
      //   {
      //     kinds: [OFFERING_KIND],
      //     limit: 10,
      //   },
      // ]);
  
      // const postedNote = getLatestEventByService(
      //   postedNoteList,
      //   "https://api.openai.com/v1/audio/transcriptions"
      // );
      // const postedNoteContent = JSON.parse(postedNote.content);
      // endpoint = postedNoteContent.endpoint;
    }

    //console.log(`postedNoteContent:`,postedNoteContent)

    // --------------------- Post to Note's endpoint -----------------------------

    const requestData = {
      'remote_url': mediaLink,
      'guid':guid
    };

    let responseData;
    //console.log("requestData:", requestData)
    try {
      const authHeader = getAuthHeader();
      console.log("got authHeader for POST:", authHeader)
      if (authHeader) {
          headers['Authorization'] = authHeader;
      }
      const response = await axios.post(
        endpoint,
        requestData,
        {headers}
      );
      responseData = response.data;
    } catch (e) {
      if (e.response && e.response.status === 402) {
        responseData = e.response.data;
      } else {
        throw new Error(`Bad Request ${e}`);
      }
    }

    storeJobHistory('Jamie','Transcript from Link',mediaLink,responseData);//TODO update to include which service is calling it?

    // --------------------- Pay invoice -----------------------------

    console.log(`------- PAYING Whisper ${index} --------`);
    console.log(responseData.pr);
    console.log("----------------------------");
    const authData = await respondToPaymentRequest(responseData);
    console.log("------- PAID Whisper ---------------");
    console.log(authData);
    console.log("----------------------------");

    // --------------------- Poll SuccessAction for Response -----------------------------

    if(authData.authCategory === "not-selected"){
      setQueuedJob(mediaLink,"WHSPR")
      console.log("ending prematurely - sign in or bitcoin connect")
      resolve(authData);
      return;
    }

    console.log(`skipPoll in runWhisper: ${skipPoll}`);
    if(skipPoll){
      resolve({
        success: responseData.paymentHash !== null,
        authCategory:authData.authCategory,
        paymentHash:responseData.paymentHash
      })
    }
    
    const paymentHash = responseData.paymentHash;
    const totalResponse = await pollUrl(
      responseData.successAction.url,
      999,
      5000,
      authData
    );
    let whisperResponse = totalResponse;
    whisperResponse.paymentHash = paymentHash;

    console.log(`------- Whisper ( ${index} ) ----------------`);
    console.log(totalResponse)
    console.log("----------------------------");

    resolve(whisperResponse);
  });
}

async function runGPT(relay, index, chatHistory) {
  return new Promise(async (resolve, reject) => {
    // --------------------- Fetch Offering Event -----------------------------
    var endpoint = "http://localhost:3010/GPT";
    var postedNote = null;
    if(debug == false){
      // const postedNoteList = await relay.list([
      //   {
      //     kinds: [OFFERING_KIND],
      //     limit: 10,
      //   },
      // ]);
  
      // postedNote = getLatestEventByService(
      //   postedNoteList,
      //   "https://api.openai.com/v1/chat/completions"
      // );
      // const postedNoteContent = JSON.parse(postedNote.content);
      // endpoint = postedNoteContent.endpoint;
      endpoint = "https://urchin-app-eswvl.ondigitalocean.app/GPT"
    }
    

    // --------------------- Post to Note's endpoint -----------------------------

    const requestData = {
      model: "gpt-3.5-turbo",
      messages: chatHistory.map((entry) => {
        return {
          role: entry.role,
          content: entry.content,
        };
      }),
    };

    let responseData;
    try {
      let headers = {};
      const authHeader = getAuthHeader();
      console.log("got authHeader for POST:", authHeader)
      if (authHeader) {
          headers['Authorization'] = authHeader;
      }
      const response = await axios.post(
        endpoint,
        requestData,
        { headers }
      );
      responseData = response.data;
    } catch (e) {
      if (e.response && e.response.status === 402) {
        responseData = e.response.data;
      } else {
        throw new Error(`Bad Request ${e}`);
      }
    }

    // --------------------- Pay invoice -----------------------------

    console.log(`------- PAYING GPT ${index} --------`);
    console.log(responseData.pr);
    console.log("----------------------------");
    const authData = await respondToPaymentRequest(responseData);
    console.log("------- PAID GPT ---------------");
    console.log("authData:",authData);
    console.log("----------------------------");

    // --------------------- Poll SuccessAction for Response -----------------------------

    if(authData.authCategory === "not-selected"){
      setQueuedJob(chatHistory,"GPT")
      console.log("ending prematurely - sign in or bitcoin connect")
      resolve(authData);
      return;
    }

    const totalResponse = await pollUrl(
      responseData.successAction.url,
      99,
      3000,
      authData
    );

    console.log("totalResponse:",totalResponse)
    const gpt = parseGPTResponse(totalResponse);

    console.log(`------- GPT ( ${index} ) ----------------`);
    console.log(`User: ${chatHistory}`);
    if(postedNote){console.log(`${postedNote.s}: ${gpt}`);}
    console.log("----------------------------");

    resolve(gpt);
  });
}

async function run_make_clips(videoId,clipRanges){
  return new Promise(async (resolve, reject) => {
    const debug2 = false;
    const endpoint = (debug2 || debug) ? ("http://localhost:4000/make_clip") :("https://rss-extractor-app-yufbq.ondigitalocean.app/make_clip")
    const requestData = {
      videoId:videoId,
      clipRanges:clipRanges
    }
    let responseData;
    try {
      let headers = {};
      const authHeader = getAuthHeader();
      console.log("got authHeader for POST:", authHeader)
      if (authHeader) {
          headers['Authorization'] = authHeader;
      }
      const response = await axios.post(
        endpoint, 
        requestData,
        { headers }
      );
      responseData = response.data;
    } catch (e) {
      if (e.response && e.response.status === 402) {
        responseData = e.response.data;
      } else {
        throw new Error(`Bad Request ${e}`);
      }
    }
  
    console.log(`------- PAYING Make Clips --------`);
    console.log(responseData.pr);
    console.log("----------------------------");
    const authData = await respondToPaymentRequest(responseData);
    console.log("------- PAID Make Clips ---------------");
    console.log("authData:",authData);
    console.log("----------------------------");
  
    // --------------------- Poll SuccessAction for Response -----------------------------
  
    if(authData.authCategory === "not-selected"){
      // setQueuedJob(prompt,"MakeClips")
      console.log("ending prematurely - sign in or bitcoin connect")
      resolve(authData);
      return;
    }
  
    const totalResponse = await pollUrl(
      responseData.successAction.url,
      10,
      3000,
      authData
    );
    console.log(`make clips totalResponse:`)
    console.log(JSON.stringify(totalResponse, null, 2));
    resolve(totalResponse);
  });
 
}

// ----------------- STABLE DIFFUSION ---------------------------

async function runStableDiffusion(relay, index, prompt, model) {
  return new Promise(async (resolve, reject) => {
    // --------------------- Fetch Offering Event -----------------------------

    var endpoint = "http://localhost:3010/STABLE";
    var postedNote = null;
    if(debug == false){
      // const postedNoteList = await relay.list([
      //   {
      //     kinds: [OFFERING_KIND],
      //     limit: 10,
      //   },
      // ]);
  
      // postedNote = getLatestEventByService(
      //   postedNoteList,
      //   "https://stablediffusionapi.com/api/v4/dreambooth"
      // );
      // const postedNoteContent = JSON.parse(postedNote.content);
      // endpoint = postedNoteContent.endpoint;
      endpoint = "https://seashell-app-gc2b7.ondigitalocean.app/STABLE"
    }

    // --------------------- Post to Note's endpoint -----------------------------

    const requestData = {
      model_id: model ?? "landscapev21",
      prompt: prompt ?? "A puppy",
      negative_prompt:
        "extra fingers, mutated hands, poorly drawn hands, poorly drawn face, deformed, ugly, blurry, bad anatomy, bad proportions, extra limbs, cloned face, skinny, glitchy, double torso, extra arms, extra hands, mangled fingers, missing lips, ugly face, distorted face, extra legs, anime",
      width: "512",
      height: "512",
      samples: "1",
      num_inference_steps: "30",
      safety_checker: "no",
      enhance_prompt: "yes",
      seed: index,
      guidance_scale: 7.5,
      multi_lingual: "no",
      panorama: "no",
      self_attention: "no",
      upscale: "no",
      embeddings: "embeddings_model_id",
      lora: "lora_model_id",
      webhook: null,
      track_id: null,
    };

    let responseData;
    try {
      let headers = {};
      const authHeader = getAuthHeader();
      console.log("got authHeader for POST:", authHeader)
      if (authHeader) {
          headers['Authorization'] = authHeader;
      }
      const response = await axios.post(
        endpoint, 
        requestData,
        { headers }
      );
      responseData = response.data;
    } catch (e) {
      if (e.response && e.response.status === 402) {
        responseData = e.response.data;
      } else {
        throw new Error(`Bad Request ${e}`);
      }
    }

    // --------------------- Pay invoice -----------------------------

    console.log(`------- PAYING SD ${index} --------`);
    console.log(responseData.pr);
    console.log("----------------------------");
    const authData = await respondToPaymentRequest(responseData);
    console.log("------- PAID SD ---------------");
    console.log("authData:",authData);
    console.log("----------------------------");

    // --------------------- Poll SuccessAction for Response -----------------------------

    if(authData.authCategory === "not-selected"){
      setQueuedJob(prompt,"STABLE")
      console.log("ending prematurely - sign in or bitcoin connect")
      resolve(authData);
      return;
    }

    console.log(`------- Waiting for Stable Diffusion... ----------------`);
    var totalResponse = await axios.get(responseData.successAction.url);
    if(totalResponse.data.state === "WORKING"){
      totalResponse = await axios.get(responseData.successAction.url);
    }

    console.log("------- IMAGES ---------------");
    //console.log("Stable Diffusion Result:", JSON.stringify(totalResponse, null, 2)); // Pretty-print with indentation
    if (totalResponse && totalResponse.data && Array.isArray(totalResponse.data.output)) {
      const outputUrls = totalResponse.data.output; // Extract the output array

      //console.log("Stable Diffusion Result URLs:", outputUrls); // Print the entire array
      console.log("Stable Diffusion Result", outputUrls[0]); // Optionally, print the first URL
    } else {
      console.log("No output URLs found in Stable Diffusion Result.");
    }
    console.log("----------------------------");

    resolve(totalResponse.data);
  });
}

// --------------------- MAIN -----------------------------

function getYoutubeVideoId(url) {
  let videoId;

  // Check if the URL starts with 'http://' or 'https://'
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'https://' + url; // Prepend with 'https://' if missing
  }

  // Create a URL object
  let parsedUrl;
  try {
    parsedUrl = new URL(url);
  } catch (error) {
    console.error('Invalid URL:', error);
    return null; // Return null if the URL is invalid
  }
  
  // Check the hostname to determine the URL format
  if (parsedUrl.hostname === "youtu.be") {
      // Extract the video ID from the pathname for shortened URLs
      videoId = parsedUrl.pathname.slice(1); // Remove the leading '/'
  } else if (parsedUrl.hostname === "www.youtube.com" || parsedUrl.hostname === "youtube.com") {
      // Get the "v" query parameter from the URL for standard URLs
      videoId = parsedUrl.searchParams.get("v");
  }
  
  // Return the video ID
  return videoId;
}


async function run_whisper_example(remoteLink, localLink,skipPoll=false,guid=null) {
  console.log("Starting run_whisper_example");
  console.log(`remoteLink: ${remoteLink}, localLink: ${localLink}`);
  console.log(`skipPoll: ${skipPoll}`);

  const relay = null;//relayInit(relayURL);
  // relay.on("connect", () => {
  //   console.log(`connected to ${relay.url}`);
  // });
  // relay.on("error", (e) => {
  //   console.log(`failed to connect to ${relay.url}: ${e}`);
  // });
  // await relay.connect();

  let result;
  try {
    if (localLink) {
      console.log("Processing local file:",localLink);
      result = await runWhisper_direct_upload(relay, 0, localLink,skipPoll);
    } else if (remoteLink) {
      console.log("Processing remote link");
      result = await runWhisper(relay, 0, remoteLink,guid,skipPoll);
    } else {
      console.log("No link provided");
      return null;
    }

    console.log("run_whisper_example processing result:", JSON.stringify(result,null,2));
    return result;
  } catch (error) {
    console.error("Error in run_whisper_example:", error);
    throw error;
  } finally {
    // relay.close();
  }
}

async function run_youtube_agent(data){
  var endpointRoute = "";
  console.log("run_youtube_agent data:",data)
  if(data.type === "Article"){
    endpointRoute = "/YT2BlogPost";
  }
  else if(data.type === "Summary"){
    endpointRoute = "/YT_Summarizer";
  }
  else if(data.type === "quote_clips"){
    endpointRoute = "/quote_clips"
  }
  else{
    return;
  }

  const youtube_agent_url = (debug === true) ? ("http://localhost:5001" + endpointRoute): ("https://yt2-blogpost-agent-w2x7c.ondigitalocean.app" + endpointRoute)
  console.log("YTA url: ", youtube_agent_url)
  const requestData = {
    'podcastName': data.ytLink,
    'customPrompt':data.customPrompt
  };

  let responseData;
  console.log("requestData:", requestData)
  try {
    let headers = {};
    const authHeader = getAuthHeader();
    console.log("got authHeader for POST:", authHeader)
    if (authHeader) {
        headers['Authorization'] = authHeader;
    }
    const response = await axios.post(youtube_agent_url, requestData, { headers });
    responseData = response.data;
  } catch (e) {
    if (e.response && e.response.status === 402) {
      responseData = e.response.data;
    } else {
      throw new Error(`Bad Request ${e}`);
    }
  }

  // --------------------- Pay invoice -----------------------------

  console.log(`------- PAYING YoutubeAgent --------`);
  console.log(responseData.pr);
  console.log("----------------------------");
  const authData = await respondToPaymentRequest(responseData);
  console.log("------- PAID YoutubeAgent ---------------");
  console.log("authData:",authData);
  console.log("----------------------------");

  // --------------------- Poll SuccessAction for Response -----------------------------
  if(authData.authCategory === "not-selected"){
    setQueuedJob(data,"yt-agent")
    console.log("ending prematurely - sign in or bitcoin connect")
    return authData;
  }
  

  const totalResponse = await pollUrl(
    responseData.successAction.url,
    99,
    3000,
    authData
  );

  console.log(`------- Youtube Agent ----------------`);
  console.log("totalResponse:",totalResponse)
  console.log("----------------------------");
  return totalResponse;
}

async function run_yitter_example(ytLink){
  let relay = null;
  // try{
  //   relay = relayInit(relayURL);
  //   relay.on("connect", () => {
  //     console.log(`connected to ${relay.url}`);
  //   });
  //   relay.on("error", (e) => {
  //     console.log(`failed to connect to ${relay.url}: ${e}`);
  //   });
  //   await relay.connect();
  // }
  // catch{
  //   console.log(`error connecting to relay: ${relayURL}`);
  // }

  var id = ytLink;
  // try{
  //   id = getYoutubeVideoId(ytLink)
  // }
  // catch{

  // }
  
  // if(!id){
  //   //ensure we actually get a video
  //   console.log("no video found");
  //   return;
  // }
  // console.log(`generating link for: ${ytLink}`)
  // console.log(`with id: ${id}`)
  
  const { itemUrl, guid } = await runRssExtractor(relay, 0, id);
  const yitterResponse = itemUrl;
  console.log(`yitterResponse: `, yitterResponse)
  return {ytLink: yitterResponse, videoId:id};
}

async function run_sd_example(sdPrompt,sdModel){
  console.log(`generating image with model: ${sdModel}`)
  const relay = null;
  // const relay = relayInit(relayURL);
  // relay.on("connect", () => {
  //   console.log(`connected to ${relay.url}`);
  // });
  // relay.on("error", (e) => {
  //   console.log(`failed to connect to ${relay.url}: ${e}`);
  // });
  // await relay.connect();

  const model = ((sdModel != "" && sdModel != null) ? sdModel : "dream-shaper-8797")

  // Use the GPT response as the prompt for runStableDiffusion
  const sdResponse = await runStableDiffusion(relay, 0, sdPrompt, model);
  
  return sdResponse;
}

async function run_gpt_example(chatHistory) {
  // const relay = relayInit(relayURL);
  // relay.on("connect", () => {});
  // relay.on("error", (e) => {});
  // await relay.connect();
  const relay = null;

  const gptResponse = await runGPT(relay, 0, chatHistory); // Pass the chat history to runGPT
  return gptResponse;
}

async function get_sd_price_estimate(sdPrompt){
  const relay = relayInit(relayURL);
  relay.on("connect", () => {
    //console.log(`connected to ${relay.url}`);
  });
  relay.on("error", (e) => {
    //console.log(`failed to connect to ${relay.url}: ${e}`);
  });
  await relay.connect();

  const postedNoteList = await relay.list([
      {
        kinds: [OFFERING_KIND],
        limit: 10,
      },
    ]);

  const postedNote = getLatestEventByService(
    postedNoteList,
    "https://stablediffusionapi.com/api/v4/dreambooth"
  );
  const postedNoteContent = JSON.parse(postedNote.content);
  //console.log(`postedNoteContent: `, JSON.stringify(postedNoteContent, null, 2));
  return postedNoteContent.cost;
}

async function getVideoDetails(videoId){
  const baseUrl = (debug === true) ? "http://localhost:5001" : "https://yt2-blogpost-agent-w2x7c.ondigitalocean.app"
  const url = baseUrl + `/get_video_details/${videoId}`
  var totalResponse = await axios.get(url);
  return totalResponse;
}

async function getVideoTimestampedTranscript(videoId){
  const baseUrl = (debug === true) ? "http://localhost:5001" : "https://yt2-blogpost-agent-w2x7c.ondigitalocean.app"
  const url = baseUrl + `/get_transcript/${videoId}`
  var totalResponse = await axios.get(url);
  return totalResponse;
}

async function fetchAllPrices(){
  const relay = relayInit(relayURL);
  relay.on("connect", () => {
    //console.log(`connected to ${relay.url}`);
  });
  relay.on("error", (e) => {
    //console.log(`failed to connect to ${relay.url}: ${e}`);
  });
  await relay.connect();

  const postedNoteList = await relay.list([
      {
        kinds: [OFFERING_KIND],
        limit: 10,
      },
    ]);

  const postedSDNote = getLatestEventByService(
    postedNoteList,
    "https://stablediffusionapi.com/api/v4/dreambooth"
  );
  const postedSDNoteContent = JSON.parse(postedSDNote.content);

  const postedGPTNote = getLatestEventByService(
    postedNoteList,
    "https://api.openai.com/v1/chat/completions"
  );
  const postedGPTNoteContent = JSON.parse(postedGPTNote.content);

  const postedYitterNote = getLatestEventByService(
    postedNoteList,
    "https://ytdl.com/"
  );
  const postedYitterNoteContent = JSON.parse(postedYitterNote.content);

  const postedWhisperNote = getLatestEventByService(
    postedNoteList,
    "https://api.openai.com/v1/audio/transcriptions"
  );
  const postedWhisperNoteContent = JSON.parse(postedWhisperNote.content);

  const postedImage2TextNote = getLatestEventByService(
    postedNoteList,
    "gpt-4-vision-preview-from-upload"
  );
  const postedImage2TextNoteContent = JSON.parse(postedImage2TextNote.content);


  const prices = {
    "gpt_price":postedGPTNoteContent.cost, 
    "sd_price" : postedSDNoteContent.cost,
    "yitter_price" : postedYitterNoteContent.cost,
    "whisper_price_fixed" : postedWhisperNoteContent.cost_fixed,
    "whisper_price_variable" : postedWhisperNoteContent.cost_variable,
    "whisper_price_unit" : postedWhisperNoteContent.cost_units,
    "image2text_price_fixed":postedImage2TextNoteContent.cost_fixed
  }

  //console.log("getAllPrices yitter_price:", prices.yitter_price)
  //console.log(`prices: `, JSON.stringify(prices, null, 2));
  //console.log(`postedWhisperNoteContent: `, JSON.stringify(postedWhisperNoteContent, null, 2));
  //console.log(JSON.stringify(prices))
  return prices;
}

async function get_gpt_price_estimate(gptPrompt){
  const relay = relayInit(relayURL);
  relay.on("connect", () => {
    //console.log(`connected to ${relay.url}`);
  });
  relay.on("error", (e) => {
    //console.log(`failed to connect to ${relay.url}: ${e}`);
  });
  await relay.connect();

  const postedNoteList = await relay.list([
      {
        kinds: [OFFERING_KIND],
        limit: 10,
      },
    ]);

  const postedNote = getLatestEventByService(
    postedNoteList,
    "https://api.openai.com/v1/chat/completions"
  );
  const postedNoteContent = JSON.parse(postedNote.content);
  //console.log(`postedNoteContent: `, JSON.stringify(postedNoteContent, null, 2));
  return postedNoteContent.cost;
}

//Runs LLM into Text to Image
async function run_example_chained_logic_app(gptPrompt,sdModel) {
  const relay = relayInit(relayURL);
  relay.on("connect", () => {
    console.log(`connected to ${relay.url}`);
  });
  relay.on("error", (e) => {
    console.log(`failed to connect to ${relay.url}: ${e}`);
  });
  await relay.connect();

  const preamble = "Write me a prompt for a text to image model that will make"
  const defaultPrompt = `a picturesque landscape of Japan that someone would hang on the wall.`
  const prompt = preamble + ((gptPrompt != "" && gptPrompt != null) ? gptPrompt : defaultPrompt)
  // --------------------- Call Endpoints -----------------------------
  
  const updatedChatHistory = [
    { role: 'user', content: "You: " + prompt },
  ];
  
  const gptResponse = await runGPT(relay, 0, updatedChatHistory);
  if(gptResponse && gptResponse.authCategory === 'not-selected'){
    return 'not-selected'
  }
  const model = ((sdModel != "" && sdModel != null) ? sdModel : "dream-shaper-8797")

  // Use the GPT response as the prompt for runStableDiffusion
  const sdResponse = await runStableDiffusion(relay, 0, gptResponse, model);


  // --------------------- Clean Up -----------------------------

  relay.close();

  return sdResponse;
}

//Runs YouTube Video Straight Into Transcription!
async function run_example_chained_logic_app2(searchTerm) {
  // const relay = relayInit(relayURL);
  // relay.on("connect", () => {
  //   console.log(`connected to ${relay.url}`);
  // });
  // relay.on("error", (e) => {
  //   console.log(`failed to connect to ${relay.url}: ${e}`);
  // });
  // await relay.connect();
  const relay = null

  // var id = null;
  // try{
  //   id = getYoutubeVideoId(youtubeURL)
  // }
  // catch{
  //   return
  // }
  // if(!id){return}
  // console.log(`generating link for: ${youtubeURL}`)
  // console.log(`with id: ${id}`)
  
  const { itemUrl, guid } = await runRssExtractor(relay, 0, searchTerm);
  const  yitterResponse = itemUrl;
  console.log(`yitterResponse: `, yitterResponse)
  console.log(`yitterResponse.authCategory:`,yitterResponse.authCategory)

  if(yitterResponse && yitterResponse.authCategory === 'not-selected'){
    return 'not-selected'
  }

  const transcript = await runWhisper(relay, 0, yitterResponse,guid)
  console.log("transcript:", transcript)
  // --------------------- Clean Up -----------------------------

  // relay.close();

  return {transcript:transcript,guid:guid,itemUrl:itemUrl};
}

async function run_sketch_to_webpage(image,gptPrompt){
  console.log(image);
  //TODO: call image2text, follow up with ChatGPT
  const relay = relayInit(relayURL);
  // const relay = relayInit(relayURL);
  // relay.on("connect", () => {
  //   console.log(`connected to ${relay.url}`);
  // });
  // relay.on("error", (e) => {
  //   console.log(`failed to connect to ${relay.url}: ${e}`);
  // });
  // await relay.connect();

  const t2iResponse = await runImage2Text(null,0,image,gptPrompt);
  if(t2iResponse && t2iResponse.authCategory === 'not-selected'){
    return 'not-selected'
  }
  //console.log("t2iResponse:",t2iResponse)
  return t2iResponse
}

function getFinalPrompt(option, customPrompt) {
  let promptText;
  let endpoint;

  switch (option) {
    case 'Summary':
      promptText = customPrompt || null;
      endpoint = 'Summary';
      break;
    case 'Article':
      promptText = customPrompt || null;
      endpoint = 'Article';
      break;
    default:
      promptText = customPrompt || defaultRSSPrompts[option] || defaultRSSPrompts['Summary'];
      endpoint = 'Summary';
      break;
  }

  return { promptText, endpoint };
}

async function run_rss_agent(data,jobIdNeedsReassignment=null){
  var endpointRoute = "";
  console.log("run_youtube_agent data:",data)

  if(data.previousJobPaymentHash){
    endpointRoute = "/AnalyzeTranscript"
  }
  else if(data.type === "Article"){
    endpointRoute = "/YT2BlogPost";
  }
  else if(data.type === "Summary"){
    endpointRoute = "/YT_Summarizer";
  }
  else if(data.type === "quote_clips"){
    endpointRoute = "/quote_clips"
  }
  else{
    return;
  }
  const youtube_agent_url = (debug === true) ? ("http://localhost:5001" + endpointRoute): ("https://yt2-blogpost-agent-w2x7c.ondigitalocean.app" + endpointRoute)
  console.log("YTA url: ", youtube_agent_url)
  const requestData = {
    'podcastName': data.ytLink,
    'customPrompt':data.customPrompt,
    'episodeItemUrl':data.episodeItemUrl,
    'guid':data.guid,
    'previousJobPaymentHash': data.previousJobPaymentHash
  };

  let responseData;
  console.log("requestData:", requestData)
  try {
    let headers = {};
    const authHeader = getAuthHeader();
    console.log("got authHeader for POST:", authHeader)
    if (authHeader) {
        headers['Authorization'] = authHeader;
    }
    const response = await axios.post(youtube_agent_url, requestData, { headers });
    responseData = response.data;
  } catch (e) {
    if (e.response && e.response.status === 402) {
      responseData = e.response.data;
    } else {
      throw new Error(`Bad Request ${e}`);
    }
  }

  // --------------------- Pay invoice -----------------------------

  console.log(`------- PAYING YoutubeAgent --------`);
  console.log(responseData.pr);
  console.log("----------------------------");
  const authData = await respondToPaymentRequest(responseData);
  console.log("------- PAID YoutubeAgent ---------------");
  console.log("authData:",authData);
  console.log("----------------------------");

  // --------------------- Poll SuccessAction for Response -----------------------------
  if(authData.authCategory === "not-selected"){
    setQueuedJob(data,"yt-agent")
    console.log("ending prematurely - sign in or bitcoin connect")
    return authData;
  }

  console.log("jobIdNeedsReassignment:",jobIdNeedsReassignment)
  if(jobIdNeedsReassignment && responseData.paymentHash){
    const newJobId = responseData.paymentHash;
    await updateJob(jobIdNeedsReassignment, {jobId:newJobId})
    console.log("reassigned jobId:",newJobId)
  }
  

  const totalResponse = await pollUrl(
    responseData.successAction.url,
    99,
    3000,
    authData
  );

  console.log(`------- Youtube Agent ----------------`);
  console.log("totalResponse:",totalResponse)
  console.log("----------------------------");
  return totalResponse;
}

export {
  run_example_chained_logic_app,
  run_example_chained_logic_app2,
  run_sketch_to_webpage,
  run_gpt_example,
  run_sd_example,
  run_yitter_example,
  run_whisper_example,
  get_gpt_price_estimate,
  get_sd_price_estimate,
  run_make_clips,
  fetchAllPrices,
  recordContactInfo,
  run_youtube_agent,
  getYoutubeVideoId,
  getVideoDetails,
  getVideoTimestampedTranscript,
  requestLNProvider,
  getQueuedJob,
  resetQueuedJob,
  getAuthHeader,
  getIsSignedIn,
  getHasEmailAndSubscription,
  PaymentChoiceStatus,
  run_rss_agent,
  getFinalPrompt,
  createGuidFromUrl
};
